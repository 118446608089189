* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
.category-title > img:first-of-type {
  margin-left: 3px;
}
img.flag{
  width: 20px!important;
  height: 20px!important;
  object-fit: contain;
}
.categories .MuiBreadcrumbs-root{
  padding: 0px 8px 8px 8px;
}
h1 {
  text-align: center;
}
.category-card{
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.category-card:before{
  z-index: 1;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(26 73 126) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.category-card.active:before, .recommend-block .category-block.active:before{
  background: linear-gradient(0deg, rgb(127, 38, 38) 10%, rgba(255,255,255,0) 100%);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
body{
  background-color: #EDEEF7!important
}
.category-block .category-title{
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  position: absolute;
  bottom: 0;
  color: white;
  margin: 0;
  padding: 10px;
  text-align: right;
  width: 100%;
  font-size: 15px;
  z-index: 9;
}
.category-block img{
  border-radius: 8px;
  object-fit: cover;
  height: 160px;
  width: 100%;
}
.category-block.active .category-title::before {
  border-color: #fff;
  width: 80%;
}
.swiper-button-disabled{
  display: none!important;
}
.filter-product .search-label {
  align-items: center;
  display: flex;
  position: relative;
}
.search-label {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 10px 0px;
}
.search-label > div{
  width: 100%;
  background-color: white;
  border-radius: 16px;
}
.search-label > div > .MuiOutlinedInput-root{
  padding-right: 14px!important;
  border-radius: 16px;
}
.product{
  /*margin: 5px;*/
  padding: 5px;
  /* background-color: white; */
  /*border-radius: 10px;*/
  width: 50%;
}

.products .infinite-scroll-component{
  margin: 8px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.product-card{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  padding: 5px;
}
.product-card .product-img{
  position: relative;
  border-radius: 7px;
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.product-card img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}
.product-card .product-body{
  height: 160px;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.product-card .title{
  font-size: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 38px;
}
.product-card .description {
  font-size: 13px;
  /*margin: 6px 0;*/
  /*height: 40px;*/
  overflow: hidden;
  display: block;
}
.product-card .price-block{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.product-card .price-block h6{
  font-size: 18px;
  margin-bottom: 0;
}
.product .addToCart{
  background-color: #6499E9;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  padding: 0px!important;
}
.product .addToCart button {
  font-size: 12px;
  padding: 0px;
  color: white!important;
  text-transform: uppercase;
  font-weight: 400;
  width: 100%;
  height: 50px;
  border-radius: 0;
}
.product .addToCart svg{
  fill: white;
}
.selects p, .selects span, .selects label, .selects svg{
  font-size: 15px;
}
.selects > div{
  margin: 10px 0;
}
.selects .Mui-focused{
  color: rgba(0, 0, 0, 0.6) !important;
}
.swiper-button-next, .swiper-button-prev{
  background-color: rgba(186, 112, 79, 0.9);
  width: 35px!important;
  height: 35px!important;
  border-radius: 14px;
  --swiper-theme-color: #fff;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 17px!important;
}
.swiper-button-next:after{
  margin-left: 2px;
}
.swiper-button-prev:after{
  margin-right: 2px;
}
.categories{
  padding: 10px 10px 0px 10px;
}
.double-categories .swiper:first-child{
  margin-bottom: 10px;
}
.product-order .modal-content{
  padding: 14px;
}
.product-order{
  z-index: 99999!important;
}
.image-gallery-slide .image-gallery-image{
  width: 100%;
  object-fit: cover;
  border-radius: 1.5rem;
  height: 58vh;
}
.product-order h3{
  padding: 10px 0;
  font-size: 20px;
  margin-bottom: 0;
  color: black;
  font-weight: 600;
}

.product-order p{
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #828690;
  min-width: 100%;
  font-size: 14px;
}
.product-order .addToCartBlock{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 0px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 30px 12px 12px;
  background-color: white;
  box-shadow: 0 0 15px rgba(65,65,65,.1);
}
.addToCartBlock{
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}
.addToCartBlock .counter p{
  font-size: 12px;
  padding: 0;
  position: absolute;
  top: -22px;
  left: 0px;
}
.price-product{
  display: flex;
  align-items: end;
  justify-content: flex-start;
  height: 40px;
  width: 54%;
  padding: 0!important;
  font-size: 28px;
  margin-bottom: 0;
  color: #6499E9;
}
.counter{
  height: 40px;
  position: relative;
  border: 1px solid #828690;
  border-radius: 8px;
  padding: 10px;
  width: 42%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.counter button{
  outline: none;
  border: none;
  font-size: 20px;
  background-color: #6499E9;
  color: #ffffff;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter span{
  font-size: 18px;
  font-weight: bold;
}
.addToCartBtn{
  width: 100%;
  background-color: #0D4C92;
  color: white;
  border: none;
  height: 50px;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 300;
}
.close-btn {
  position: absolute!important;
  right: 10px;
  height: 20px;
  min-width: 30px!important;
  width: 30px;
  top: 10px;
  background-color: rgba(100, 153, 233, .9) !important;
  padding: 20px!important;
  z-index: 999;
}
.close-btn svg{
  fill: white!important;
}
.cart-button{
  width: 100%;
  position: fixed;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  background-color: #0D4C92;
  padding: 20px;
  color: white;
  z-index: 9999;
}
body{
  position: relative;
  margin-bottom: 80px!important;
}
.copyright{
  height: 30px;
  text-align: center;
  font-size: 18px;
  background-color: #6499E9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  text-transform: uppercase;
  margin-top: 40px;
}
.mainSkeleton{
  margin: 10px!important;
}
.cart-modal .MuiPaper-root{
  padding-bottom: 0px;
}
.cart-product{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 12px;
  color: #3d4152;
  position: relative;
}
.cart-modal .cart-header{
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #6499E9;
  margin-bottom: 10px;
}
.cart-modal .btn-close{
  background-color: transparent!important;
  top: 0;
  right: 0;
  height: 60px;
  padding: 0!important;
  width: 60px;
}
.cart-modal .cart-header h2{
  padding: 10px;
  color: #3d4152;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 60px;
}
.cart-product:before{
  content: "";
  border-bottom: 1px solid #d2d2d2;
  width: calc(100% - 24px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0%;
  margin: 0 auto;
  text-align: center;
  transition: all 400ms ease;
}
.cart-product .cart-product-photo {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  overflow: hidden;
  background-color: white;
}
.cart-product .cart-product-photo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}
.cart-product h3{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
  margin-bottom: 8px;
}
.cart-product .attr{
  font-size: 12px;
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: 8px;
}
.cart-product h3 svg{
  font-size: 30px;
  margin-top: -5px;
  fill: #cd5c5c;
}
.cart-product h4{
  font-size: 21px;
}
.cart-product-body{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 14px;
}
.cart-product-body .quantityBlock{
  margin: 0;
}
.cart-product .counter button {
  padding-bottom: 4px;
  border: none;
  font-size: 20px;
  background-color: #6499E9;
  color: #ffffff;
  width: 28px;
  height: 44px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0!important;
}
.cart-product .counter {
  height: 36px;
  position: relative;
  border: 1px solid #828690;
  border-radius: 14px;
  padding: 0px;
  width: 42%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout{
  padding: 30px 10px;
}
.checkout h2{
  margin-bottom: 16px;
}
.checkout form > div{
  display: flex;
  flex-direction: column;
}
.checkout form > div{
  margin-bottom: 20px;
}
.checkout form label{
  color: #111;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.checkout form input, .checkout textarea{
  border: 2px solid #111;
  border-radius: 12px;
  color: #111;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  min-height: 48px;
  padding: 10px 14px;
  transition: .3s cubic-bezier(.4,0,.2,1);
  width: 100%;
}
.phone-input{
  border: 2px solid #111;
  border-radius: 12px;
  display: flex;
  height: 48px;
  width: 100%;
}
.phone-input__prefix{
  align-items: center;
  border-radius: 12px;
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding: 10px 8px 10px 14px;
  position: relative;
  width: 70px;
}
.phone-input .input {
  border: none;
  border-left: 1px solid #c4c4c4;
  border-radius: 0 12px 12px 0;
  height: 44px;
  min-height: 44px;
  padding: 0 16px;
  width: calc(100% - 70px);
}
.phone-input input:focus-visible{
  outline: none;
}
.checkout-price{
  margin-top: 20px;
  flex-direction: row!important;
  justify-content: space-between!important;
}
.checkout-price h4{
  color: #111;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-right: 0;
}
.checkout .validation-error{
  color: #B00020;
  font-weight: 500;
  font-size: 15px;
  margin-top: 4px;
}
.error{
  color: #B00020!important;
}
.checkout .btn-order{
  background: #0D4C92;
  border-color: #0D4C92;
  color: #fff;
  width: 100%;
  min-height: 56px;
  border-radius: 12px;
}
.checkout .btn-order:hover{
  background: #6499E9;
  color: #fff;
}
.recommend-block img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recommend-block h5{
  font-size: 25px!important;
  padding: 20px!important;
  text-align: left!important;
}
.recommend-block > div{
  height: 240px;
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  transition: all 400ms ease;
  position: relative;
  margin: 10px 10px 0px;
}
.recommend-block > div:before{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(71, 45, 45) 0%, rgba(255,255,255,0) 100%);
  transition: all 400ms ease;
}
.balance{
  padding: 10px;
}
.balance > div {
  color: white;
  background-color: #609966e6;
  border-radius: 20px;
  padding: 14px;
  overflow: hidden;
  position: relative;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.balance > div:before, .balance > div:after{
  background: hsla(0,0%,86%,.2);
  border-radius: 50%;
  content: "";
  height: 15rem;
  position: absolute;
  width: 14.063rem;
}
.balance > div:before{
  left: -100px;
  top: -110px;
}
.balance > div:after{
  left: -100px;
  top: 20px;
}
.balance > div p{
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 6px;
}
.balance > div h4{
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}
.balance .card-balance > div{
  position: absolute;
  top: 5px;
  right: -10px;
}
.balance > div button svg{
  fill: white;
}
.MuiTooltip-arrow{
  color: white!important;
}
.MuiTooltip-popper .MuiTooltip-tooltip{
  margin-top: 6px!important;
  font-size: 14px!important;
  max-width: 300px!important;
}
.checkout .btn{
  padding-bottom: 8px;
}
.cashback-price h6{
  font-weight: 400;
}
.cashback-price{
  border-top: 1px solid #6499E9;
  padding-top: 24px;
  font-weight: 300!important;
}
.all-price{
  margin-top: 10px;
}
.card-balance img{
  position: absolute;
  width: 75px;
  bottom: -5px;
  right: 0px;
}

.product-order .modal-content > div:first-child{
  padding-bottom: 14px!important;
  height: 77vh;
}
.product-order.mt-3, .product-order .modal-dialog{
  margin: 0!important;
}
.product-order .modal-content{
  border-radius: 0;
}
.modal-content > div:first-child{
  overflow-y: scroll;
  padding: 0 1px 145px;
}
.modal-content > div:first-child::-webkit-scrollbar{
  display: none;
}
.back-btn{
  margin-left: 5px!important;
  margin-bottom: 5px!important;
}
.addToCart svg{
  width: 25px;
  height: 25px;
}
.addToCart{
  padding: 5px!important;
  width: 100%!important;
  height: 50px!important;
  border-radius: 8px!important;
}
#Capa_1{
  margin-right: 10px;
  margin-left: 5px;
}
span.radio-type{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
}
.order_types{
  margin-top: 30px;
}
.Toastify__toast-body{
   white-space: pre-line;
}

.double-categories > div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.category-card{
  width: 47%;
  margin: 5.3px;
  position: relative;
}
.category-block{
  position: relative;
}
.discount-price{
  text-decoration: line-through;
  color: #8B8B8B;
  font-size: 16px;
}
.price-sale{
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.price-sale > .on-sale:nth-child(2){
  margin-left: 5px;
}
.on-sale{
  background-color: #e61d30;
  color: #fff;
  padding: 4px 8px;
  line-height: 1.3;
  text-transform: uppercase;
  border-radius: 70px;
  font-weight: 600;
  font-size: .85rem;
}
.c-green{
  background-color: yellow;
  color: #000;
}
.product-order p.discount-price{
  padding-bottom: 0;
  font-size: 22px;
}
.products .price-sale{
  left: 0px!important;
  top: 5px!important;
  right: 0px!important;
}